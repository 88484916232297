import React, { useEffect } from "react"
import Header from "../components/Header"
import PreHero from "./PreHero"
import Hero from "../components/home/Hero"
import Sogehts from "../components/home/Sogehts"
import { Link } from "react-router-dom"

const Confirm: React.FC = () => {
	function track(label: string) {
		try {
			// eslint-disable-next-line
			let dataLayer = window["dataLayer"] || []
			dataLayer.push({ event: "footer", link: label })
		} catch (e) {}
	}

	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		})
	}, [])

	return (
		<>
			<div className="light">
				<Header />
				<Hero type="prescreen" />
				<Sogehts type="prescreen" />
				<div className="mx-auto -mt-12 mb-12 max-w-[1400px] text-center lg:-mt-24 lg:mb-24">
					<a href="https://www.volvic.de/" target="_blank" className="btn mx-auto  font-Veener text-[42px] uppercase text-white drop-shadow-md hover:drop-shadow-xl">
						{" "}
						Zur Volvic Website
					</a>
				</div>
			</div>
		</>
	)
}
export default Confirm
