import React, { useEffect } from "react"
import DocumentMeta from "react-document-meta"
import { useNavigate, Link } from "react-router-dom"
import RosaSection from "../components/elements/BeigeSection"
import Header from "../components/Header"

const Terms: React.FC = () => {
	const navigate = useNavigate()

	// SEO Meta Tags

	const meta = {
		// title: "Mit merci zum Muttertag filmreif Danke sagen!",
		// canonical: "https://arvato-hawaiian-tropic-2023.de/",
		// meta: {
		// name: {
		// robots: "noindex, nofollow",
		// },
		// },
	}

	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		})
	}, [])

	return (
		<div>
			<div className="light bg-black">
				<Header type="logo" />
				<div className="mx-auto  bg-black  py-8 px-4 text-white">
					<div className="mx-auto max-w-[800px]">
						<h2 className="h2 font-Veener mb-8 mt-12 text-center text-2xl text-orange lg:mt-12">Teilnahme&shy;bedingungen</h2>
						<h3 className="h3 py-4 tracking-wider">1. Veranstalter</h3>
						<p className="mb-4">
							Die Wilkinson Sword GmbH, Schützenstraße 110, D-42659 Solingen (nachfolgend "Veranstalter" oder "Wilkinson Sword" genannt) veranstaltet im Zeitraum vom 25.10.2024 bis
							zum 25.11.2024, 23:59 Uhr, eine zeitlich begrenzte Cashback-Aktion (die "Aktion")*. Die Aktion wird von der Magellan GmbH, Oststraße 10, 40211 Düsseldorf, Deutschland
							und der direct services Gütersloh GmbH, Reinhard-Mohn-Str. 300, 33333 Gütersloh, Deutschland durchgeführt.
						</p>
						<p className="mb-4 text-[10px]">*Aktionsende bereist früher möglich, wenn 4.600 Teilnehmer erfolgreich an der Cashback Aktion teilgenommen haben.</p>

						<h3 className="h3 py-4 tracking-wider">2. Teilnahme</h3>
						<h3 className="pb-4 font-bold">A. Allgemeines</h3>
						<p className="mb-4">
							Teilnahmeberechtigt ist jede natürliche Person mit Wohnsitz in Deutschland, die zum Zeitpunkt der Teilnahme an der Aktion das achtzehnte (18.) Lebensjahr vollendet
							hat, unbeschränkt geschäftsfähig ist und einen qualifizierenden Einkauf wie unten beschrieben getätigt hat (nachfolgend Teilnehmer").
						</p>
						<p className="mb-4">
							Von der Teilnahme ausgeschlossen sind Mitarbeiter von Wilkinson Sword und der mit ihr verbundenen Unternehmen im Sinne von § 15 AktG und deren Angehörige sowie
							Mitarbeiter von Kooperationspartnern des Veranstalters, die an der Gestaltung oder Durchführung der Aktion beteiligt waren oder sind. Außerdem sind alle Mitarbeiter
							der dm-drogerie markt GmbH + Co. KG von der Teilnahme ausgeschlossen.
						</p>
						<p className="mb-4">
							Eine gewerbliche Teilnahme oder eine durch Dritte vermittelte Teilnahme (z.B. Gewinnspielservices, Makler oder Suchmaschinen) ist nicht zulässig. Darüber hinaus
							behält sich der Veranstalter das Recht vor, Personen von der Teilnahme auszuschließen, bei denen der Verdacht besteht, dass sie unerlaubte Hilfsmittel verwenden oder
							anderweitig versuchen, sich oder Dritten durch Manipulation einen Vorteil zu verschaffen (nach billigem Ermessen des Veranstalters). Der Veranstalter hat in solchen
							Fällen das Recht, die Auszahlung des Gewinns zu verweigern.
						</p>
						<h3 className="pb-4 font-bold">B. Teilnahmevoraussetzungen und Ablauf</h3>
						<p className="mb-4">
							Um an der Aktion teilzunehmen, muss der Teilnehmer eines der Aktionsprodukte im angegebenen Einkaufszeitraum bei der dm-drogerie markt GmbH + Co. kaufen (auch
							Online-Käufe sind möglich).
						</p>
						<p className="mb-4">
							Die Teilnahme an der Aktion ist vom 25.10.2024 bis zum 25.11.2024, 23:59 Uhr, durch Hochladen des Rechnungsbelegs und Eingabe der erforderlichen Daten auf der
							Aktionsseite unter https://wilkinsonsword.de/pages/wilkinson-6-cashback (die "Aktionsseite") möglich. Der Scan oder das Foto des Original-Rechnungsbelegs muss das
							teilnehmende Produkt, das teilnehmende Geschäft, den Preis, die Rechnungsbelegnummer und das Kaufdatum deutlich erkennen lassen.
						</p>
						<p className="mb-4">
							Die Teilnahme an der Aktion ist kostenlos. Die Teilnehmer tragen lediglich ihre regulären Kosten für die von ihnen genutzten Kommunikationsmittel (z. B. Kosten für
							die Internetverbindung). Jeder Teilnehmer kann nur in seinem eigenen Namen teilnehmen. Eine Mehrfachteilnahme ist nicht möglich. Pro Teilnahme darf nur ein
							Rechnungsbeleg eingereicht werden. Jeder Rechnungsbeleg darf nur einmal im Rahmen der Aktion verwendet werden.
						</p>
						<p className="mb-4">
							Die Teilnahme an der Aktion ist nur über das Formular auf der Aktionsseite möglich. Um teilzunehmen, muss der Teilnehmer das Formular während des angegebenen
							Teilnahmezeitraums mit gültigen, erforderlichen Daten (Name, Alter, Kaufinformationen, E-Mail-Adresse, IBAN und Kontoinhaber) ausfüllen und bestätigen.
						</p>
						<p className="mb-4">
							Die Rückerstattung der 70% Rabatt für Rasierer erfolgt nach Eingabe der erforderlichen persönlichen Daten und erfolgreicher Bestätigung Ihrer Eingaben auf das
							angegebene Bankkonto bei vollständiger und korrekter Angabe der IBAN. Jeder Teilnehmer ist für die Richtigkeit seiner Daten, insbesondere seiner Bankverbindung,
							selbst verantwortlich.
						</p>
						<p className="mb-4">
							Datenschutzbestimmungen und Teilnahmebedingungen müssen akzeptiert werden. Der Teilnehmer hat dafür Sorge zu tragen, dass durch seine Teilnahme keine Ansprüche wegen
							Verletzung der Privatsphäre oder des allgemeinen Persönlichkeitsrechts entstehen, keine Rechte und/oder Interessen Dritter verletzt werden und keine Zahlungsansprüche
							jeglicher Art entstehen.
						</p>
						<p className="mb-4">Eine Kombination mit anderen dm Gutscheinen oder Promotion ist möglich. Der Gewinnanspruch ist nicht auf Dritte übertragbar.</p>
						<p className="mb-4">Der Original-Rechnungsbeleg ist als Kaufnachweis bis nach der Verlosung aufzubewahren und auf Verlangen vorzulegen.</p>
						<h3 className="pb-4 font-bold">C. Kauf- und Teilnahmezeitraum</h3>
						<p className="mb-4">
							Der Kaufzeitraum für die Aktionsprodukte ist vom 25.10.2024 bis zum 25.11.2024, 23:59 Uhr (der "Kaufzeitraum"). Der Teilnahmezeitraum für den Rechnungseingangs-Upload
							ist vom 25.10.2024 bis zum 30.11.2024, 23:59 Uhr (der "Teilnahmezeitraum").
						</p>
						<h3 className="pb-4 font-bold">D. Aktionsprodukte </h3>
						<p className="mb-4">
							Die Aktion gilt für alle folgenden Produkte (nachfolgend die "Produkte"), die zwischen dem 25.10.2024 und dem 25.11.2024, 23:59 Uhr bei der dm-drogerie markt GmbH +
							Co in Deutschland oder online innerhalb Deutschlands ("Teilnehmender Händler") gekauft werden:
						</p>
						<p className="mb-2"> Teilnehmende Aktionsprodukte inkl. GTIN: </p>
						<ul className="mb-4">
							<li>• Wilkinson 6 Rasierapparat (GTIN: 4027800143702)</li>
							<li>Teilnehmende Händler innerhalb Deutschlands:</li>
							<li>• dm-drogerie markt GmbH + Co. KG</li>
						</ul>
						<h3 className="h3 py-4 tracking-wider">3. Auswahl der Gewinner, Benachrichtigung der Gewinner und Inanspruchnahme der Preise</h3>
						<p className="mb-4">
							Die Rückerstattung der 70% Rabatt für den Wilkinson Sword 6 Rasierer erfolgt nach Eingabe der erforderlichen persönlichen Daten und erfolgreicher Bestätigung Ihrer
							Eingaben auf das angegebene Bankkonto bei vollständiger und korrekter Angabe der IBAN.
						</p>
						<p className="mb-4">
							Wenn eins der oben genannten Angaben nicht vollständig oder korrekt sind, verfällt die Rückerstattung der 70% Rabatt für den Wilkinson Sword 6 Rasierer, und ein
							anderer wird nach dem Ermessen des Veranstalters aus dem ursprünglichen Pool der teilnahmeberechtigten Beiträge ausgewählt.
						</p>
						<p className="mb-4">
							Es ist nicht möglich, die 70% Rabatt auf den Wilkinson Sword 6 Rasierer anders als festgelegt auszuzahlen, z. B. in Sachwerten, durch Umtausch oder Übertragung auf
							andere Personen. Ein Teilnehmer kann auf die Geld zurück Erstattung verzichten.
						</p>

						<h3 className="h3 py-4 tracking-wider">4. Beendigung der Aktion</h3>
						<p className="mb-4">
							Die Aktion kann jederzeit ohne Vorankündigung und ohne Angabe von Gründen geändert, verlängert, vorübergehend ausgesetzt oder vorzeitig beendet werden. Hieraus
							ergeben sich keine Ansprüche gegen den Veranstalter. Dies kann insbesondere dann der Fall sein, wenn Fehlfunktionen, Fälschungen, Viren oder ähnliche Schäden
							auftreten oder ein Fall von Missbrauch der Gewinnspiele vorliegt und die Verwaltung, Sicherheit, Fairness, Integrität oder der Ablauf der Aktion beeinträchtigt wird.
							Aus einer vorzeitigen Beendigung können keine Ansprüche abgeleitet werden.
						</p>

						<h3 className="h3 py-4 tracking-wider">5. Sonstiges</h3>
						<p className="mb-4">Es gilt das Recht der Bundesrepublik Deutschland unter Ausschluss des UN-Übereinkommens über Verträge über den internationalen Warenkauf.</p>
						<p className="mb-4">
							Sollten einzelne Bestimmungen der Teilnahmebedingungen unwirksam sein oder werden, so bleibt die Wirksamkeit der übrigen Teilnahmebedingungen unberührt. An ihre
							Stelle tritt diejenige gültige Bestimmung, die dem Zweck der unwirksamen Bestimmung am nächsten kommt.
						</p>
						<p className="mb-4">
							Wilkinson Sword behält sich das Recht vor, diese Teilnahmebedingungen jederzeit und ohne vorherige Ankündigung zu ändern. Dies gilt auch für die vorzeitige Beendigung
							der Aktion. Die Änderung tritt mit der Veröffentlichung der geänderten Teilnahmebedingungen auf https://wilkinsonsword.de/pages/wilkinson-6-cashback in Kraft. Die
							Teilnahmebedingungen sind für die Dauer der Aktion gültig. Es gilt ausschließlich das Recht der Bundesrepublik Deutschland. Alle Fragen oder Kommentare zur Aktion
							sind per E-Mail an cashback@wilkinson6-gewinnspiel.de zu richten.{" "}
						</p>
						<h3 className="h3 py-4 tracking-wider">6. Datenschutz</h3>
						<p className="mb-4">
							Die nachfolgenden Datenschutzhinweise informieren die Teilnehmer über Art, Umfang und Zweck der Erhebung und Verwendung ihrer personenbezogenen Daten im Rahmen der
							Aktion durch den Veranstalter als datenschutzrechtlich Verantwortlichem. Mit der Teilnahme an der Aktion und der Erteilung der Einwilligung willigt der Teilnehmer
							ein, dass Wilkinson Sword seine personenbezogenen Daten (Name, Geburtsdatum, E-Mail-Adresse, IBAN und Kontoinhaber) speichern und verarbeiten darf.
						</p>
						<p className="mb-4">Eine Weitergabe von Daten an Dritte erfolgt nur, wenn dies für die Abwicklung der Aktion erforderlich und gesetzlich zulässig ist.</p>
						<p className="mb-4">
							Im Rahmen der Aktion werden folgende personenbezogene Daten der Teilnehmer von der Magellan GmbH erhoben und verarbeitet und für die Teilnahme, Durchführung und
							Abwicklung der Gewinnspiele, einschließlich der Zustellung der Preise und ggf. der Veröffentlichung des Vornamens des Gewinners, nach Maßgabe dieser
							Teilnahmebedingungen und auf Grundlage einer Einwilligung verwendet.
						</p>
						<p className="mb-4">Die Verarbeitung der personenbezogenen Daten des Teilnehmers erfolgt zum Zweck der Durchführung und Abwicklung</p>
						<p className="mb-4">- der Aktion: Vor- und Nachname, Geburtsdatum, E-Mail-Adresse, IBAN und Kontoinhaber</p>
						<p className="mb-4">
							Anschließend werden die oben genannten personenbezogenen Daten zu Werbezwecken verwendet, wenn der Teilnehmer dem Erhalt von Werbemitteilungen zugestimmt hat. Nach
							Beendigung der Aktion wird der Veranstalter die personenbezogenen Daten der Teilnehmer, die dem Erhalt von Werbeinformationen nicht zugestimmt haben, bis zum 17.
							Februar 2025 löschen.
						</p>
						<p className="mb-4">
							Weitere Informationen darüber, wie der Veranstalter personenbezogene Daten verarbeitet, einschließlich Informationen über die Rechte der Teilnehmer, finden Sie unter:
							https://wilkinsonsword.de/policies/privacy-policy. Die Teilnehmer haben das Recht, jederzeit Auskunft über die Verarbeitung ihrer personenbezogenen Daten zu erhalten,
							die Berichtigung, Löschung oder Einschränkung der Verarbeitung ihrer personenbezogenen Daten zu verlangen und die zur Verfügung gestellten personenbezogenen Daten in
							einem strukturierten, allgemein gebräuchlichen und maschinenlesbaren Format zu erhalten und an einen anderen für die Verarbeitung Verantwortlichen zu übermitteln. Die
							Teilnehmer haben das Recht, ihre Einwilligung zu widerrufen, wenn der Veranstalter personenbezogene Daten auf der Grundlage ihrer Einwilligung verarbeitet. Zu diesem
							Zweck können sich die Teilnehmer an die folgende Adresse wenden: Wilkinson Sword GmbH, Schützenstraße 110, 42659 Solingen. Die Bitte um Auskunft und Widerruf kann der
							Teilnehmer per E-Mail an den Veranstalter unter cashback@wilkinson6-gewinnspiel.de richten. Im Übrigen gelten die allgemeinen Datenschutzrichtlinien des
							Veranstalters.
						</p>
						<h3 className="h3 py-4 tracking-wider">7. Haftung</h3>
						<p className="mb-4">
							Der Veranstalter haftet für eigenes Verschulden und grobe Fahrlässigkeit, sowie das seiner gesetzlichen Vertreter, leitenden Angestellten oder sonstigen
							Erfüllungsgehilfen nach den gesetzlichen Bestimmungen. Gleiches gilt für die Übernahme von Garantien oder sonstiger verschuldensunabhängiger Haftung sowie für
							Ansprüche nach dem Produkthaftungsgesetz oder bei schuldhafter Verletzung von Leben, Körper oder Gesundheit.
						</p>
						<p className="mb-4">
							Der Veranstalter haftet für die eigene, die seiner Vertreter, leitenden Angestellten und einfachen Erfüllungsgehilfen einfach fahrlässige Verletzung wesentlicher
							Vertragspflichten, d.h. solcher Pflichten, auf deren ordnungsgemäße Erfüllung die Teilnehmer bei der Durchführung der Aktion regelmäßig vertrauen dürfen; in diesem
							Fall ist die Haftung der Höhe nach auf den typischerweise vorhersehbaren Schaden begrenzt.
						</p>
						<p className="mb-4">Jede weitere Haftung des Veranstalters ist ausgeschlossen.</p>
					</div>
					<div className=" py-10 pt-5 text-center text-xl text-white">
						<Link to="/" className="btn btn-hero mt-8 rounded bg-white px-12 py-4 text-white">
							Zurück
						</Link>
					</div>
				</div>
			</div>
		</div>
	)
}
export default Terms
