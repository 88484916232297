import { BrowserRouter, Route, Routes } from "react-router-dom"
import Footer from "./components/Footer"
import Confirm from "./nav/Confirm"
import Contact from "./nav/Contact"
import Error from "./nav/Error"
import FAQ from "./nav/FAQ"
import Imprint from "./nav/Imprint"
import PreScreen from "./nav/PreScreen"
import Privacy from "./nav/Privacy"
import Promoover from "./nav/Promoover"
import Terms from "./nav/Terms"
import Store from "./store/store"
import Home from "./nav/Home"
import NewsletterConfirm from "./nav/NewsletterConfirm"

declare global {
	interface Window {
		Cookiebot: any
	}
}
const App = () => {
	//  Mon Jun 10 2024 09:00:00 UTC+0200 (Mitteleuropäische Sommerzeit)   https://time.is/de/Unix_time_converter
	const isPromoOver = parseInt(Math.floor(Date.now() / 1000).toString()) > 1718002800

	return (
		<BrowserRouter basename={`${process.env.PUBLIC_URL}`}>
			<div>
				<Store>
					<main className="mx-auto  w-full">
						<Routes>
							<Route path="/" element={<Home />} />
							{/* <Route path="/" element={<Home />} /> */}
							<Route path="/promoover" element={<Promoover />} />
							<Route path="/prescreen" element={<PreScreen />} />
							<Route path="/danke" element={<Confirm />} />
							<Route path="/faq" element={<FAQ />} />
							<Route path="/datenschutz" element={<Privacy />} />
							<Route path="/teilnahmebedingungen" element={<Terms />} />
							<Route path="/impressum" element={<Imprint />} />
							<Route path="/kontakt" element={<Contact />} />
							<Route path="/:err" element={<Error />} />
							<Route path="/teilnahmebestaetigung" element={<NewsletterConfirm />} />
						</Routes>
						<Footer />
					</main>
				</Store>
			</div>
		</BrowserRouter>
	)
}

export default App
